<template>
    <div class="container">
        <div class="search" style="display:flex">
            <el-form
                ref="form"
                style="flex:1"
                :inline="true"
                :model="search"
                class="demo-form-inline"
            >
                <el-form-item>
                    <el-input
                        v-model.trim="search.kname"
                        placeholder="模糊搜索名称"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model.trim="search.code"
                        placeholder="套餐编号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="search.status"
                        multiple
                        placeholder="状态集合"
                    >
                        <el-option label="测试" :value="0"></el-option>
                        <el-option label="上架" :value="1"></el-option>
                        <el-option label="下架" :value="2"></el-option>
                        <el-option label="已删除" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="search.is_event"
                        clearable
                        placeholder="是否为事件"
                    >
                        <el-option label="否" :value="0"></el-option>
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="全部" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="Search">查询</el-button>
                </el-form-item>
            </el-form>
            <div>
                <router-link :to="'/iot/packadd/' + 0">
                    <el-button type="primary">新建</el-button>
                </router-link>
            </div>
        </div>
        <!-- 列表 -->
        <el-table border v-bind:data="list" @sort-change="Sortchange">
            <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
            <el-table-column label="套餐名称" sortable>
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐时长" sortable>
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.time +
                            '(' +
                            scope.row.SetMeal.time_unit +
                            ')'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="套餐状态">
                <template v-slot="scope">
                    <el-select
                        v-model="scope.row.SetMeal.status"
                        @change="changeStatus(scope.row.SetMeal)"
                    >
                        <el-option label="测试" :value="0"></el-option>
                        <el-option label="上架" :value="1"></el-option>
                        <el-option label="下架" :value="2"></el-option>
                        <el-option label="已删除" :value="3"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="文件时长(天)" sortable>
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.file_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="服务等级">
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.level === 1
                            ? 'Starter'
                            : scope.row.SetMeal.level === 2
                            ? 'Standard'
                            : scope.row.SetMeal.level === 3
                            ? 'Pro'
                            : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="是否针对事件存储">
                <template v-slot="scope">
                    <span>{{
                        scope.row.SetMeal.is_event == 0
                            ? '否'
                            : scope.row.SetMeal.is_event == 1
                            ? '是'
                            : '未知'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="说明">
                <template v-slot="scope">
                    <span>{{ scope.row.SetMeal.explain }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300px">
                <template v-slot="scope">
                    <router-link
                        style="margin-right:10px"
                        :to="'/iot/pack/sale/' + scope.row.SetMeal.code"
                    >
                        <el-button type="info" size="small">折扣信息</el-button>
                    </router-link>
                    <router-link
                        style="margin-right:10px"
                        :to="'/iot/packadd/' + scope.row.SetMeal.code"
                    >
                        <el-button type="primary" size="small">修改</el-button>
                    </router-link>
                    <router-link
                        style="margin-right:10px"
                        :to="'/iot/pack/distribute/' + scope.row.SetMeal.code"
                    >
                        <el-button type="info" size="small">套餐分配</el-button>
                    </router-link>
                    <!-- <el-button type="danger" size="small" @click="deletePack(scope.row.SetMeal.code)">删除</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            v-model:currentPage="search.page"
            :page-size="search.limit"
            layout="total, prev, pager, next"
            :total="total"
        >
        </el-pagination>
    </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../axios/pack'
import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        code: '',
        status: [0,1],
        is_event: -1,
        order_field: '',
        order_action: 1,
      },
      list: [],
      total: 0,
      currencylist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetCurrency () {
      this.api.CurrencyList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.currencylist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Sortchange (column) {
      switch (column.column.label) {
        case '套餐名称':
          this.search.order_field = 'name'
          break;
        case '套餐时长':
          this.search.order_field = 'time'
          break;
        case '套餐价格/售价':
          this.search.order_field = 'price'
          break;
        case '币种':
          this.search.order_field = 'currency'
          break;
        case '文件时长(天)':
          this.search.order_field = 'file_time'
          break;
      }
      this.search.order_action = this.search.order_action== 1 ? 2:1
      this.Search()
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search(){
      api.PackageList({
        page: this.search.page,
        limit: this.search.limit,
        kname: this.search.kname,
        code: this.search.code,
        status: JSON.stringify(this.search.status),
        is_event: this.search.is_event,
        order_field: this.search.order_field,
        order_action: this.search.order_action,
      }).then(res => {
        console.log('PackageList: ',res)
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    changeStatus (item) {
      api.PackageChangeStatus({
        set_meal_code: item.code,
        status: item.status,
      }).then(res => {
        if (res.data.code == 200) {
            this.$message.success('状态修改成功')
            this.Search()
            return
        }
        this.$message.error('状态修改失败' + res.data.msg)
      })
    },
    deletePack(item) {
      this.$confirm('是否删除该套餐?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        api.PackDelete({code: item}).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    changeTime(item) {
      return util.dateFormat(item)
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.GetCurrency()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
